import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Icon from '../Icon';
import { ReactComponent as ImdbSvgComponent } from '../../assets/imdb.svg';
import styles from './movie.module.scss';
import { Movie } from '@types';

const WIDTH = 742;
const HEIGHT = 417;

interface MovieDetailViewerProps {
    show: boolean;
    model?: Movie;
    onHide?: () => void;
}

export default function MovieDetailViewer(props: MovieDetailViewerProps) {
    const [vsource, setVSource] = useState('');
    const [meta, setImdbMeta] = useState<ImdbVideoMeta>({});
    const { title, storyline, tags, imdb } = props.model || {};
    const handleHide = () => {
        setVSource('');
        setImdbMeta({});
        props.onHide?.();
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (imdb) {
            // Trailer
            (async () => {
                const vid = await window.imdb.trailers.parse(imdb);
                const src = `https://www.imdb.com/video/imdb/${vid}/imdb/embed?width=${WIDTH}&autoplay=false`;

                if (vid)
                    setVSource(src);
            })();
            // IMDb meta
            (async () => {
                // TODO: Temp
                if (window.location.hostname !== 'localhost')
                    return;

                try {
                    const meta = await fetch(`/api/imdb/${imdb}/`, { signal }).then(r => r.json());

                    if (meta) {
                        setImdbMeta(meta);
                        console.log(`Found live meta for ${imdb}`, meta);
                    }
                }
                catch (ex) {
                    console.warn(`API is unavailable.`, ex);
                }
            })();
        }

        return () => controller.abort();
    }, [imdb]);

    return (
        <Modal show={props.show} onHide={handleHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    {
                        !!vsource &&
                        <iframe src={vsource}
                            title={`IMDb ${title}`}
                            className="w-100 mb-4"
                            style={{ height: '33vh' }}
                            width={WIDTH} height={HEIGHT}
                            allowFullScreen={true}
                            ref={$iframe => {
                                // These are non-standard attributes so they don't appear in the TypeScript JSX attributes list,
                                // But they do actually change the appearance & functionality of the <iframe />
                                if ($iframe) {
                                    $iframe.setAttribute('mozallowfullscreen', 'true');
                                    $iframe.setAttribute('webkitallowfullscreen', 'true');
                                    $iframe.setAttribute('frameBorder', 'no');
                                    $iframe.setAttribute('scrolling', 'off');
                                }
                            }}
                        >
                        </iframe>
                    }
                    <p>{storyline?.replace('—', '— ')}</p>

                    {
                        !!Object.keys(meta).length &&
                        <>
                            <ul className={styles['video-credits']}>
                                {
                                    [
                                        { label: 'Directors', people: meta.directors },
                                        { label: 'Writers', people: meta.writers },
                                        { label: 'Stars', people: meta.stars }
                                    ].map(({ label, people }) => {
                                        return (
                                            <li key={label}>
                                                <label className="me-2">{label}:</label>
                                                <ul>
                                                    {
                                                        people?.map(p => (
                                                            <li key={p.nm}>
                                                                <a href={`https://www.imdb.com/name/${p.nm}/`} className="d-inline-flex align-items-center" target="_blank" rel="noreferrer">
                                                                    {p.fullName}<Icon variant="open-outline" className="ms-1" />
                                                                </a>
                                                                {!!p.desc && <span className={styles['credit-desc']}>{p.desc}</span>}
                                                            </li>
                                                            )
                                                        ) ?? 'N/A'
                                                    }
                                                </ul>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </>
                    }

                    <div>
                        {!!tags?.length && tags.map(t => <span key={t} className="badge bg-black me-1">{t}</span>)}
                        {
                            !!imdb &&
                            <a href={`https://imdb.com/title/${imdb}/`} className="text-light float-end" target="_blank" rel="noreferrer">
                                <span className="d-flex align-items-center mt-1">
                                    <ImdbSvgComponent />
                                    <Icon variant="open-outline" className="ms-1" />
                                </span>
                            </a>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
