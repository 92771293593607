import { Video } from '@types';
import { throttle } from 'lodash';
import { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import overlay from 'videojs-overlay';
import SubtitleManager from '../managers/SubtitleManager';
import styles from './video.module.scss';

const subtitleManager = new SubtitleManager();
const options = {
    controls: true,
    autoplay: true,
    playsinline: false,
    nodownload: true,
    loop: false,
    disablepictureinpicture: true
};

interface VideoPlayerProps {
    title: string;
    video: Video;
    onTimeUpdate?: (video: HTMLVideoElement) => void;
    onEnded?: (p0: unknown) => void;
}

/** Component that renders a video player for the specified video. */
export default function VideoPlayer(props: VideoPlayerProps) {
    const video = props.video;
    const refVideo = useRef<HTMLDivElement>(null);
    const refPlayer = useRef<VideoJsPlayer>();

    useEffect(() => {
        const $root = document.querySelector<HTMLDivElement>('#root');

        if ($root)
            $root.classList.add('video-playing');

        return () => $root?.classList.remove('video-playing');
    }, []);

    const handleKeyUp = (e: KeyboardEvent) => {
        // Ignore events when it's a child of the header - using the navbar search
        if (document.querySelector('header')!.contains(e.target as Element))
            return;
        else if (!refPlayer.current)
            return;

        const player = refPlayer.current;

        switch (e.code || e.which) {
            case 'Space':
                e.preventDefault();
                if (player) {
                    if (player.paused())
                        player.play();
                    else
                        player.pause();
                }
                break;
            case 'ArrowRight':
            case 199:
                e.preventDefault();
                player.currentTime(player.currentTime() + 10);
                player.el().classList.add('skip-forward');
                setTimeout(() => player.el().classList.remove('skip-forward'), 250);
                break;
            case 'ArrowLeft':
            case 200:
                e.preventDefault();
                player.el().classList.add('skip-backward');
                player.currentTime(player.currentTime() - 10);
                setTimeout(() => player.el().classList.remove('skip-backward'), 250);
                break;
            case 'KeyY':
            case 208:
                e.preventDefault();
                player.requestFullscreen();
                break;
            default: break;
        }
    };
    // Throttled callback for tracking the current timestamp of the playing video
    const trackTimeWatched = throttle(vid => {
        if (vid.currentTime)
            localStorage[`${video.id}:meta`] = JSON.stringify({ currentTime: vid.currentTime, lastWatched: new Date().toISOString() });
    }, 1000);
    // Callback for tracking the current timestamp of the playing video
    const handleTimeUpdate = ({ target }: Event & { target: HTMLElement }) => {
        const vid = target.querySelector<HTMLVideoElement>('video')!;
        props.onTimeUpdate?.(vid);
        trackTimeWatched(vid);
    };
    // Callback for when the video is done playing
    const handleEnded = (e: unknown) => { props.onEnded?.(e); };

    useEffect(() => {
        const xhr = { cancelled: false };

        // make sure Video.js player is only initialized once
        if (!refPlayer.current) {
            const videoElement = document.createElement('video-js');
            videoElement.id = 'video-player';
            videoElement.classList.add('vjs-theme-forest', 'vjs-waiting');
            refVideo.current?.appendChild(videoElement);

            const player = (window as any).player = refPlayer.current = videojs(videoElement,
                { ...options, src: video.url },
                () => {
                    if (localStorage[`${video.id}:meta`]) {
                        const meta = JSON.parse(localStorage[`${video.id}:meta`]);
                        const currentTime = meta.currentTime;

                        if (!isNaN(currentTime))
                            player.currentTime(currentTime);

                        if (!xhr.cancelled)
                            player.play();
                    }
                }
            );
            const title = `<div class="${styles['video-title']} display-4">${props.title}</div>`;

            overlay.call(player, {
                overlays: [
                    { start: 'loadstart', content: title, end: 'playing', align: 'top' },
                    { start: 'pause', content: title, end: 'playing', align: 'top' }
                ]
            });
            player.ready(async () => {
                if (!xhr.cancelled)
                    videoElement.scrollIntoView({ block: 'end', behavior: 'smooth' });

                if (video.subs) {
                    const { url } = await subtitleManager.get(video.id) ?? {};

                    if (url && !xhr.cancelled) {
                        player.addRemoteTextTrack({
                            kind: 'subtitles',
                            src: url,
                            label: 'English [CC]',
                            language: 'en',
                            mode: 'hidden',
                            default: false
                        }, true);
                    }
                }
            });
            player.on('timeupdate', handleTimeUpdate);
            player.on('ended', handleEnded);

            if (video.url) {
                player.autoplay(options.autoplay);
                player.src([{ src: video.url, type: video.contentType }]);
            }

            // Maintain the video focus when the user clicks on the control bar
            // So if they click the fullscreen btn and then try to skip ahead (right arrow key) it will actually skip ahead
            player.el().querySelector('.vjs-control-bar')?.addEventListener('click', () => refVideo.current?.focus());

            let lastTouchStart = Date.now();
            let lastTouchEnd = Date.now();

            player.el().addEventListener('touchstart', e => {
                lastTouchStart = Date.now();
            });
            player.el().addEventListener('touchend', e => {
                // Time between start & end is < 250ms
                if (Date.now() - lastTouchStart < 250) {
                    // Time between 1st & this tap is < 750ms
                    if (Date.now() - lastTouchEnd < 750) {
                        const start = (e as TouchEvent).changedTouches[0];
                        const right = start.clientX > window.innerWidth / 2;

                        if (right)
                            handleKeyUp(new KeyboardEvent('keyup', { code: 'ArrowRight', which: 199 }));
                        else
                            handleKeyUp(new KeyboardEvent('keyup', { code: 'ArrowLeft', which: 200 }));
                    }

                    lastTouchEnd = Date.now();
                }
            });
        }
        else {
            // you can update player here [update player through props]
            const player = refPlayer.current;
            player.autoplay(options.autoplay);
            player.src([{ src: video.url!, type: video.contentType }]);
        }

        return () => xhr.cancelled = true as any;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video, refVideo, props.title]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = refPlayer.current;
        const $body = document.body;
        $body.addEventListener('keydown', handleKeyUp);

        return () => {
            if (player) {
                player.dispose();
                refPlayer.current = undefined;
            }
            $body.removeEventListener('keydown', handleKeyUp);
        };
    }, [refPlayer]);

    return (
        <div data-vjs-player>
            <div ref={refVideo}></div>
        </div>
    );
}
